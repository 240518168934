import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ActionLink from "../components/action-link"

const SuccessPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Succesverhalen" />
    <h1>Succesverhalen</h1>
    <p>
      Wij streven ernaar jongeren een niveau hoger te brengen. Dit is ons in het verleden met meerdere jongeren reeds gelukt.<br />
      <br />
      1e Groep: 5 van de 6 hebben na de opleiding een vaste baan.<br />
      2e Groep: 7 cursisten hebben allemaal en hoger niveau bereikt bij ROC Mondriaan.<br />
      <br />
      Bij ROC Mondriaan is er een geslaagde proef van bekwaamheid afgelegd onder toeziend oog van Dhr. Verschuur van Savantis (Het kenniscentrum voor beroepsonderwijs bedrijfsleven)
      </p>
      <p>
      Na een goed doorlopen opleiding geven wij ook een certificaat van deelname aan de cursist. Ieder jaar wordt ons netwerk van bedrijven die onze leerlingen stageplaatsen geven steeds groter.
    </p>
    
    <h2>Ook aan de slag als stucadoor?</h2>
    <p>Traub stuc staat bekend om zijn niet schoolse aanpak. Naast hard werken, vinden wij een goede sfeer ook belangrijk. Wij geloven dat iedereen een talent heeft. Wij helpen je graag om een mooi vak te leren, zodat je altijd je eigen broek op kunt houden.</p>
    <ActionLink to="/getting-started" >Direct aanmelden ›</ActionLink>

    <h2>Zij gingen je voor</h2>
    
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
      {
      data.files.edges.map(edge => {
        return <Img className="w-full" fluid={edge.node.childImageSharp.fluid} />
      })
      }
    </div>
    
  </Layout>
)

export default SuccessPage

export const query =  graphql`
{
  files: allFile(filter: {relativeDirectory: {eq: "success"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`